import * as React from 'react'
import { GatsbyBrowser } from 'gatsby'
import { Layout } from './src/components/layouts'
import TagManager from 'react-gtm-module'
import './src/styles/global.css'


let isTagManagerInitialized = false

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {

  if (!isTagManagerInitialized) {
    const tagManagerArgs = {
      gtmId: process.env.GOOGLE_TAG_MANAGER_ID || '',
    }
    TagManager.initialize(tagManagerArgs)
    isTagManagerInitialized = true
  }

  return (
    <Layout>
      {element}
    </Layout>
  )
}